.taskbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, #f9f9f9, #e8e8e8); /* Subtle gradient */
  border-bottom: 1px solid #d1d1d1; /* Soft border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Light shadow for separation */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  z-index: 1000;
}

.taskbar-left,
.taskbar-center,
.taskbar-right {
  display: flex;
  align-items: center;
}

.taskbar-left {
  gap: 10px;
}

.app-logo {
  height: 32px; /* Slightly smaller for a cleaner look */
  width: auto;
}

.taskbar-center {
  flex-grow: 1;
  justify-content: center;
}

.date-time {
  font-size: 0.9rem;
  font-weight: 400;
  color: #555555; /* Neutral dark gray */
  white-space: nowrap; /* Prevent wrapping */
}

.taskbar-right {
  gap: 16px; /* Spaced-out elements */
  transform: translateX(-30px); /* Moves the section left as requested */
}

.wallet-address,
.token-price {
  font-size: 0.85rem;
  font-weight: 500;
  color: #333333; /* Slightly darker for emphasis */
  white-space: nowrap;
}

.wallet-address:hover,
.token-price:hover {
  color: #000000; /* Darker hover effect */
}

.token-price {
  display: flex;
  align-items: center;
  gap: 4px; /* Space between label and value */
}

.connect-wallet-btn {
  background-color: #ffffff; /* Clean white button */
  border: 1px solid #d1d1d1;
  color: #555555;
  font-size: 0.85rem;
  padding: 4px 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
}

.connect-wallet-btn:hover {
  background-color: #e8e8e8;
  color: #333333;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15); /* Subtle shadow on hover */
}

.settings-btn {
  font-size: 1.2rem;
  color: #555555;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease, transform 0.2s ease;
}

.settings-btn:hover {
  color: #000000; /* Darker hover */
  transform: scale(1.1); /* Slight pop effect */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wallet-address,
  .token-price {
    font-size: 0.75rem; /* Adjust font size for smaller screens */
  }

  .taskbar-right {
    gap: 8px; /* Reduce gap on smaller screens */
  }
}
/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 50px; /* Position below the taskbar */
  left: 10px; /* Align with the logo */
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  padding: 8px 12px;
  z-index: 1001;
}

.dropdown-menu a {
  display: block;
  font-size: 0.9rem;
  color: #333333;
  text-decoration: none;
  padding: 6px 0;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.dropdown-menu a:hover {
  color: #000000;
  background-color: #f0f0f0;
}