/* RoleSelection.css */

/* Container */
.role-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  /* Header */
  .role-selection-header {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0.5rem;
    -webkit-text-stroke: 1px #ffffff; /* Adds a white stroke */
    text-shadow: 
      2px 2px 4px rgba(0, 0, 0, 0.5), /* Drop shadow effect */
      0 0 10px rgba(255, 255, 255, 0.5); /* Subtle outer glow */
  }
  
  /* Subtext */
  .role-selection-subtext {
    font-size: 1rem;
    color: #6e6e73;
    margin-bottom: 2rem;
  }
  
  /* Button Container */
  .role-selection-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Viewer Button */
  .role-selection-viewer-button {
    background-color: #007aff;
    color: #ffffff;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: background-color 0.2s ease;
  }
  
  .role-selection-viewer-button:disabled {
    background-color: #b0b0b5;
    cursor: not-allowed;
  }
  
  .role-selection-viewer-button:hover:not(:disabled) {
    background-color: #005bb5;
  }
  
  /* Age Verification Modal */
  .age-verification-modal {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .age-verification-title {
    font-size: 1.75rem;
    font-weight: 600;
    color: #000000;
    margin-bottom: 1rem;
  }
  
  .age-verification-text {
    font-size: 1rem;
    color: #3a3a3c;
    margin-bottom: 2rem;
  }
  
  .age-verification-button {
    background-color: #007aff;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 0.5rem;
    transition: background-color 0.2s ease;
  }
  
  .age-verification-button:hover {
    background-color: #005bb5;
  }
  .role-selection-logo {
    display: block;
    margin: 0 auto 1rem; /* Center the logo and add spacing below it */
    width: 100px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2)); /* Add subtle shadow */
  }
  .role-selection-logo-container {
    position: relative;
    display: inline-block;
    margin: 0 auto 1rem; /* Center the container and add spacing */
    width: 120px; /* Adjust width as needed */
    height: 120px; /* Match the logo dimensions */
  }
  
  .role-selection-logo-container::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px; /* Larger than the logo for visibility */
    height: 150px; /* Larger than the logo for visibility */
    border-radius: 50%; /* Rounded shape */
    background: rgba(0, 0, 0, 0.2); /* Slightly darker blur */
    filter: blur(20px); /* Create a visible blur effect */
    z-index: -1; /* Place it behind the logo */
  }
  
  .role-selection-logo {
    display: block;
    width: 100px; /* Adjust width as needed */
    height: auto; /* Maintain aspect ratio */
    position: relative; /* Ensure it stays above the blur */
  }
  .role-selection-main-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .role-selection-main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
  }
  
  .instructions-box,
  .updates-box {
    flex: 1;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .instructions-title,
  .updates-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .instructions-list,
  .updates-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #555;
  }
  
  .instructions-list li,
  .updates-list li {
    margin-bottom: 10px;
    font-size: 1em;
  }