.container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.85); /* MacOS-like translucent background */
    border-radius: 15px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: #333; /* Neutral text color */
    backdrop-filter: blur(10px); /* Adds blur effect for a modern touch */
  }
  
  .header {
    font-size: 2rem;
    text-align: center;
    color: #007aff; /* MacOS accent blue */
    font-weight: bold;
    margin-bottom: 20px;
    text-shadow: 0px 1px 4px rgba(0, 122, 255, 0.4); /* Slight glow for focus */
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin-bottom: 15px;
    color: #666; /* Subtle gray text */
    font-size: 1.1rem;
  }
  
  .input, .textarea {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    background-color: #f9f9f9; /* Light background for inputs */
    color: #333;
    font-size: 1rem;
    margin-bottom: 15px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inset shadow */
  }
  
  .textarea {
    resize: none;
    height: 80px; /* Adjust height for text area */
  }
  
  .button {
    background-color: #007aff; /* MacOS blue for primary buttons */
    color: white;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Adds depth */
    transition: all 0.2s ease;
  }
  
  .button:hover {
    background-color: #005bb5; /* Darker blue on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
  }
  
  .image-preview {
    max-width: 200px;
    height: auto;
    border-radius: 8px;
    margin-top: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .preview-header {
    font-size: 1.2rem;
    color: #007aff; /* Accent blue */
    text-align: center;
    margin-top: 20px;
  }
  
  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: -10px;
  }
  
  @media (max-width: 768px) {
    .container {
      width: 90%;
      margin: 10px auto;
      padding: 15px;
    }
  
    .header {
      font-size: 1.5rem;
    }
  
    .button {
      font-size: 0.9rem;
      padding: 8px;
    }
  }