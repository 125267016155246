/* Global App styling */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
}

/* Main App Container */
.App {
  width: 100%;
  max-width: 1000px;
  margin: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8); /* Frosted glass effect */
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  color: #333;
}

/* Navbar Styling */
.navbar {
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  padding: 12px 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.nav-links {
  display: flex;
  gap: 15px;
  align-items: center;
}

.nav-link {
  color: #007aff;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #005bb5;
}

/* Wallet Address Style */
.wallet-address {
  color: #555;
  font-size: 14px;
  margin-top: 5px;
}

/* General Button Styling */
.button {
  background: linear-gradient(135deg, #007aff, #005bb5);
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.button:hover {
  background: linear-gradient(135deg, #005bb5, #003e87);
}

/* ConnectWallet Button Style */
.connect-wallet {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Section Styling */
.section {
  margin: 30px 0;
  padding: 20px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.section h2 {
  font-size: 24px;
  color: #007aff;
  margin-bottom: 10px;
}

/* Specific Styling for Images */
.thumbnail {
  max-width: 300px;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Center Content */
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Link Button Styling */
.link-button {
  background: none;
  border: none;
  color: #007aff;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
  transition: color 0.3s ease;
}

.link-button:hover {
  color: #005bb5;
}

.app.dark {
  --background-color: #1c1c1e;
  --text-color: #f5f5f7;
}
/* App.css */
.app-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.main-content {
  position: relative; /* Acts as the draggable boundary */
  overflow: visible; /* Ensures content stays within the parent */
  height: 100vh; /* Ensure full height for bounding */
  width: 100%; /* Ensure full width for bounding */
}
#windows-container {
  position: relative; /* Must be relative to ensure proper bounds for children */
  width: 100%;
  height: 100%;
  overflow: hidden; /* Ensures no content spills out */
  z-index: 1000; /* High enough to allow interaction with child elements */
}