/* src/Components/Dock.css */
.dock {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px 12px 0 0;
  backdrop-filter: blur(10px);
  z-index: 1000;
  pointer-events: none; /* Prevent Dock from interfering with dragging */
}
.dock-item {
  margin: 0 15px;
  pointer-events: auto; /* Allow clicking on Dock buttons */
  transition: transform 0.3s;
}

.dock-item:hover {
  transform: scale(1.2);
}

.dock-item img {
  width: 32px;
  height: 32px;
}