/* Window.css */

.window {
  position: relative; /* Required for draggable behavior */
  background-color: #ffffff;
  border: 1px solid #d1d1d6;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: auto; /* Ensure content stays within window bounds */
  z-index: 9999; /* Ensure proper stacking */
  transition: box-shadow 0.2s ease, transform 0.2s ease; /* Smooth interaction feedback */
}

.window-minimized {
  height: 40px; /* Adjust as needed for minimized state */
  overflow: auto; /* Hide content when minimized */
}

.window-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out controls and title */
  background-color: #f2f2f7;
  padding: 0.75rem;
  cursor: move; /* Indicates draggable area */
  z-index: 10; /* Ensure header is above other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for focus */
  border-bottom: 1px solid #d1d1d6; /* Separate header from content */
}

.window-controls {
  display: flex;
  align-items: center;
}

.window-controls button {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  transition: transform 0.1s ease, opacity 0.2s ease; /* Hover feedback */
}

.window-controls button:hover {
  opacity: 0.8;
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.close-btn {
  background-color: #ff5f56;
}

.minimize-btn {
  background-color: #ffbd2e;
}

.maximize-btn {
  background-color: #27c93f;
}

.window-title {
  font-weight: bold;
  font-size: 1rem; /* Ensure the title stands out */
  color: #000;
  flex-grow: 1; /* Allow title to take up available space */
  text-align: center; /* Center-align the title */
}

.window-content {
  padding: 1rem;
  height: 100%;
  overflow-y: auto; /* Ensure content scrolls if needed */
}

.window-content::-webkit-scrollbar {
  width: 8px; /* Thin scrollbar */
}

.window-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Subtle dark scrollbar */
  border-radius: 4px;
}

.window-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4); /* Darker on hover */
}

.window:hover {
  box-shadow: 0 24px 48px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}